export const SURVEY_BASE_FRAGMENT = `
  fragment surveyBaseFragment on Survey {
    uid
    code
    title
    strategy
    instructions
    startTime
    startTimestamp
    endTime
    endTimestamp
  }
`;
